import React, { useState, createContext, useEffect } from "react";
import ReactDOM from 'react-dom';
import * as Survey from "survey-react"
import "survey-react/modern.css";
import "survey-react/survey.css";
import "../index.css";
import SurveyContainer from "../components/survey"
import AvrtData from "../components/avrtdata"
import PatientSearch from "../components/patient_search"
import Amplify, { Auth } from "aws-amplify";
import awsExports from "../aws-exports";
import { withAuthenticator, AmplifyAuthenticator, AmplifySignIn, AmplifySignOut } from '@aws-amplify/ui-react'
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
// import { Button, t } from '@material-ui/core';
import { Card, CardHeader, CardContent } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import awsGlobal from "../aws-global"
import axios from "axios";


Amplify.configure(awsExports);
const StaffPortal = (props) => {
    const [idToken, setIdToken] = useState('')
    const [authState, setAuthState] = React.useState();
    const [user, setUser] = React.useState();
    const [cognito, setCognito] = useState({})
    const [region, setRegion] = useState('')
    const [awsUrl, setAwsUrl] = useState('')
    const [rootRegion, setRootRegion] = useState('uk2')
    const [gridData, setGridData] = useState([])
    useEffect(() => {
        console.log('region', region)
        if (region) {
            console.log('REGION', region)
            console.log('JSON', awsGlobal.aws_json_patient)
            // setAwsUrl(awsGlobal.aws_json_patient[awsGlobal.env][region].api_gw_url)
        }
    }, [region, idToken])


    useEffect(() => {
        if (authState === undefined) {
            Auth.currentAuthenticatedUser().then(authData => {
                setAuthState(AuthState.SignedIn);
                setUser(authData);
            });
        }
        onAuthUIStateChange((nextAuthState, authData) => {
            setAuthState(nextAuthState);
            setUser(authData)
            Auth.currentSession().then((res) => {
                let accessToken = res.getAccessToken();
                let jwt = accessToken.getJwtToken();
                //You can print them to see the full objects
                console.log('jwt from staffportal top', res.getIdToken());
                setIdToken(res.getIdToken().jwtToken)

                // getTasks(res.getIdToken().jwtToken)
                console.log('payload', res.getIdToken().payload)
                // for staff the region isn't in the cognito record
                console.log('awsGlobal', awsGlobal)
                console.log('awsGlobal.env', awsGlobal.env)
                console.log('rootRegion', rootRegion)
                let fetch_url = awsGlobal.aws_json_staff[awsGlobal.env][rootRegion].api_gw_url + '/staff_regions'
                console.log('getting region list from', fetch_url)
                axios.get(fetch_url, { headers: { Authorization: 'Bearer ' + res.getIdToken().jwtToken, 'Content-Type': 'application/json' } }).then(function (response) {
                    console.log('response', response.data)
                    setRegion(response.data)
                }).catch(function (error) {
                    console.log('error', error)
                })

                setCognito(res.getIdToken().payload)
            });
        });


    }, [authState]);
    // useEffect(() => {
    //     Auth.currentSession().then((res) => {
    //         let accessToken = res.getAccessToken();
    //         let jwt = accessToken.getJwtToken();
    //         //You can print them to see the full objects
    //         console.log(res)
    //         console.log(`myAccessToken: ${JSON.stringify(accessToken)}`);
    //         console.log(`myJwt: ${jwt}`);
    //         setIdToken(res.getIdToken().jwtToken)
    //         console.log(res.getIdToken())
    //         console.log(idToken)

    //     });
    // }, []);
    async function signOut() {
        try {
            await Auth.signOut();
            window.location.reload()
        } catch (error) {
            console.log('error signing out: ', error);
        }
    }
    // survey.defaultBootstrapMaterialCss.navigationButton = "btn btn-green";
    // survey.defaultBootstrapMaterialCss.rating.item = "btn btn-default my-rating";
    // ReactDOM.render(<Survey.Survey model={survey} onValueChanged={sendDataToServer} />, document.getElementById("surveyElement"));
    // ReactDOM.render(<h1>footer</h1>, document.getElementById("footer"))
    return authState === AuthState.SignedIn && user ? (
        <div>
            <Container maxWidth="lg">
                <h1><a href="/staff">AVRT Staff Portal</a></h1>

                <Button color="primary" variant="contained" onClick={() => { signOut(); }}>
                    Sign out
                </Button>
                <Card variant="outlined">
                    <PatientSearch auth={idToken} region={region} />

                </Card>

            </Container>


        </div>

    ) : (
        <AmplifyAuthenticator>
            <AmplifySignIn headerText="AVRT Staff Portal sign-in" slot="sign-in" hideSignUp></AmplifySignIn>
        </AmplifyAuthenticator>
    );
}
export default StaffPortal
// export default withAuthenticator(StaffPortal)
