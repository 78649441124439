import React, { useState, createContext, useEffect } from "react";
import ReactDOM from 'react-dom';
import * as Survey from "survey-react"
import "survey-react/modern.css";
import "survey-react/survey.css";
import "../index.css";
import awsvars from "../aws.json"
import axios from "axios";
import { Grid, Card, CardHeader, CardContent } from '@material-ui/core';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red, amber, green } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Parser from 'html-react-parser';
const Factor = (props) => {

    const [avrtData, setAvrtData] = useState({})
    const [ragColour, setRagColour] = useState(red[500])
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [additionalDescription, setAdditionalDescription] = useState("")
    const [triggers, setTriggers] = useState([])
    // var survey = new Survey.Model();

    // ReactDOM.render(<Survey.Survey model={survey} />, document.getElementById("surveyElement"));;
    useEffect(() => {

        if (props.data.rag_overall === 'red') {
            setRagColour("#b81820")
        }
        if (props.data.rag_overall === 'amber') {
            setRagColour("#f5b131")
        }
        if (props.data.rag_overall === 'green') {
            setRagColour("#1db58c")
        }

        setTitle(props.data.display + ': ' + props.value)
        // console.log('PROPS.DATA->', props.data)
        setDescription(props.data.description)
        setAdditionalDescription(props.data.additional_description)
        if (props.data.trigger) {
            setTriggers(props.data.trigger)
        }
        // console.log('TRIGGERS', triggers)
    }, []);



    // survey.defaultBootstrapMaterialCss.navigationButton = "btn btn-green";
    // survey.defaultBootstrapMaterialCss.rating.item = "btn btn-default my-rating";
    // ReactDOM.render(<Survey.Survey model={survey} onValueChanged={sendDataToServer} />, document.getElementById("surveyElement"));
    // ReactDOM.render(<h1>footer</h1>, document.getElementById("footer"))
    return (

        <div>
            <b>{props.field_desc}</b> {(typeof (props.value[0]) === 'number') ? parseFloat(props.value[0].toFixed(2)) : props.value.join(', ')}

            <p>
                {Parser(description)}
            </p>

            {triggers.map(({ details }) => {
                if (details) {
                    return (
                        <>

                            {/* {details.rag == 'red' && <span>&#128308; </span>}
                                    {details.rag == 'amber' && <span>&#128993; </span>}
                                    {details.rag == 'green' && <span>&#128994; </span>} */}
                            <span>&#9888;&#65039; </span>
                            {details.msg}
                        </>
                    )
                }
                else {
                    return (<>/</>)
                }

            })}
            {Parser(additionalDescription)}


        </div>



    );
}

export default Factor;