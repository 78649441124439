
import React, { useState, createContext, useEffect } from "react";
import ReactDOM from 'react-dom';
import * as Survey from "survey-react"
import "survey-react/modern.css";
import "survey-react/survey.css";
import "../index.css";
import SurveyContainer from "./survey"
import Amplify, { Auth } from "aws-amplify";
import awsExports from "../aws-exports";
import { withAuthenticator } from '@aws-amplify/ui-react'
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { getThemeProps } from "@mui/system";
import { Grid } from '@material-ui/core';
import * as qs from 'query-string';
import CircularProgress from '@mui/material/CircularProgress';
import awsvars from "../aws.json"
import axios from "axios";
import fileDownload from 'js-file-download'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
Amplify.configure(awsExports);


const VitallEvent = (props) => {

}

const VitallEvents = (props) => {
    const [idToken, setIdToken] = useState('')
    const [loaded, setLoaded] = useState(false)
    const [events, setEvents] = useState([])
    const [url, setUrl] = useState('')
    async function fetch() {
        if (props.awsUrl === '') {
            return
        }
        console.log('STAFF:VITALLEVENTS:ALL:props', props)
        if (!props.patient_id) {
            return
        }
        const eventsx = await axios.get(props.awsUrl + '/vitall_status?patient_id=' + props.patient_id, { headers: { Authorization: 'Bearer ' + props.auth, 'Content-Type': 'application/json' } });
        console.log('STAFF VITALLEVENTS: FROM SERVER ', eventsx)
        setEvents(eventsx.data)
        setLoaded(true)
    }


    useEffect(() => {
        fetch()

    }, [props.awsUrl, props.auth]);

    var params = qs.parse(window.location.search, { ignoreQueryPrefix: true })
    return (
        <div>
            {loaded ?
                <>
                    {events.map(({ first_ts, order_id, events }) => {
                        return <div>

                            <Typography variant="h6">Order {order_id}</Typography>
                            Events:
                            <ul>
                                {events.map(({ status_code, status_name, timestamp }) => {
                                    return <li>
                                        {new Date(timestamp * 1000).toISOString().slice(0, 19).replace('T', ' ')} - {status_name} ({status_code})
                                    </li>
                                })}
                            </ul>
                        </div>
                    })

                    } </> : <>< CircularProgress /></>
            }
        </div >
    );
}
export default withAuthenticator(VitallEvents)
