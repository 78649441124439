import React, { useState, createContext, useEffect } from "react";
import ReactDOM from 'react-dom';
import * as Survey from "survey-react"
import "survey-react/modern.css";
import "survey-react/survey.css";
import "../index.css";
import awsvars from "../aws.json"
import axios from "axios";
import AvrtGroup from "./avrtgroup"
import AvrtGroupSummary from "./avrtgroupsummary"
import { Grid, Card, CardHeader, CardContent } from '@material-ui/core';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red, yellow, green, blue } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Factor from "../ui_components/factor"
import Trigger from "../ui_components/trigger"
import External from "../ui_components/external"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';

const Serological = (props) => {
    const [seroData, setSeroData] = useState([])
    const [avrtData, setAvrtData] = useState([])
    const [triggers, setTriggers] = useState([])
    const [patient, setPatient] = useState({})
    const [headerData, setHeaderData] = useState([])
    const [external, setExternal] = useState({ 'fields': [] })
    const [patientName, setPatientName] = useState({})
    const [avrt, setAvrt] = useState({})
    const [loaded, setLoaded] = useState(false)
    // var survey = new Survey.Model();

    // ReactDOM.render(<Survey.Survey model={survey} />, document.getElementById("surveyElement"));;

    useEffect(() => {
        if (props.awsUrl === '') {
            return
        }
        async function fetch() {

            console.log('STAFF:SEROpropsupdate:props', props)
            if (!props.patient_id) {
                console.log('no patient id, exiting')
                return
            }
            await axios.get(props.awsUrl + '/serology_staff?patient_id=' + props.patient_id, { headers: { Authorization: 'Bearer ' + props.auth, 'Content-Type': 'application/json' } }).then(
                function (response) {
                    console.log('STAFF SERO FROM SERVER propsupdsate ', response.data)
                    setSeroData(response.data.remap)
                    setLoaded(true)
                }
            )
        }
        if (!props.auth) {
            console.log('exiting cos props.auth is ', props.auth)
            return
        }
        console.log('started')
        fetch()
    }, [props.awsUrl, props.auth, props.patient_id]);
    //         useEffect(() => {
    //             console.log('UPDATING AVRTDATA',avrtDataFull)
    //             if (avrtDataFull.length===0){
    //                 console.log('no data to update')
    //                 return
    //             }
    //             console.log('pushing avrtdata to the right places')
    //             setTriggers(avrtDataFull.avrt_fe.triggers)
    //             // setPatient(avrtDataFull.avrt_fe.patient)
    //             setHeaderData(avrtDataFull.avrt_fe.int_data)
    //             setExternal({'description':'ICHRON scores', 'fields': avrtDataFull.ichron_score})
    //             setAvrtData(avrtDataFull.avrt_fe.data)
    //             console.log('set sample data')
    //             console.log(avrtData)
    //  }, [avrtDataFull]);
    // useEffect(() => {
    //     async function fetch() {

    //         console.log('STAFF:SEROpropsupdate:props', props)
    //         if (!props.patient_id) {
    //             console.log('no patient id, exiting')
    //             return
    //         }
    //         await axios.get(props.awsUrl + '/serology_staff?patient_id=' + props.patient_id, { headers: { Authorization: 'Bearer ' + props.auth, 'Content-Type': 'application/json' } }).then(
    //             function (response) {
    //                 console.log('STAFF SERO FROM SERVER propsupdsate ', response.data)
    //                 setSeroData(response.data.remap)
    //                 setLoaded(true)
    //             }
    //         )
    //     }
    //     console.log('>>>AUTH UPDATE')
    //     fetch()
    // }, [props.auth, props.patient_id]);

    // survey.defaultBootstrapMaterialCss.navigationButton = "btn btn-green";
    // survey.defaultBootstrapMaterialCss.rating.item = "btn btn-default my-rating";
    // ReactDOM.render(<Survey.Survey model={survey} onValueChanged={sendDataToServer} />, document.getElementById("surveyElement"));
    // ReactDOM.render(<h1>footer</h1>, document.getElementById("footer"))

    // build the table up


    return (
        <div>

            <h2>Serological Data</h2>
            {(loaded && seroData) ?
                <>
                    <Grid container spacing={2}>
                        {
                            seroData.map(({ field, value, units, display, bloods_key }) => {
                                if (display === true) {
                                    return (
                                        <Grid item xs={6} md={4} lg={3}>

                                            <b>{bloods_key}</b><br />{value} {units}
                                        </Grid>

                                    )
                                }

                            }

                            )}
                    </Grid>
                </> : <>< CircularProgress /></>
            }
            {/* <Table>
            {
                seroData.map(({  field, value, units, display }) => {
                    if (display===true){
                         return (
                        <TableRow>
                            <TableCell>
                                <b>{field}</b>
                            </TableCell>
                             <TableCell>
                                {value} {units}
                            </TableCell>
                        
                        </TableRow>
                        )
                    }
                   
                }
                
                )}
            </Table> */}
        </div >

    );
}

export default Serological;