import React, { useState, createContext, useEffect } from "react";
import ReactDOM from 'react-dom';
import * as Survey from "survey-react"
import "survey-react/modern.css";
import "survey-react/survey.css";
import "../index.css";
import awsvars from "../aws.json"
import axios from "axios";
import Factor from "../ui_components/factor"
import { Grid, Card } from '@material-ui/core';
import AvrtElement from "./avrtelement"
const AvrtGroup = (props) => {

    const [avrtData, setAvrtData] = useState({})
    // var survey = new Survey.Model();

    // ReactDOM.render(<Survey.Survey model={survey} />, document.getElementById("surveyElement"));;
    useEffect(() => {



    }, []);



    // survey.defaultBootstrapMaterialCss.navigationButton = "btn btn-green";
    // survey.defaultBootstrapMaterialCss.rating.item = "btn btn-default my-rating";
    // ReactDOM.render(<Survey.Survey model={survey} onValueChanged={sendDataToServer} />, document.getElementById("surveyElement"));
    // ReactDOM.render(<h1>footer</h1>, document.getElementById("footer"))
    return (
        <div>

            <div>
                <a id={props.id}><h3>{props.name}
                    <br />
                    {/* {[...Array(props.rag.red)].map((e, i) => <span>&#128308; </span>)}
                    {[...Array(props.rag.amber)].map((e, i) => <span>&#128993; </span>)}
                    {[...Array(props.rag.green)].map((e, i) => <span>&#128994; </span>)} */}
                </h3></a>
                {/* <Grid item xs={12} md={12} lg={12}> */}
                <Grid container spacing={2}>
                    {/* <b>RED: {props.rag.red} AMBER: {props.rag.amber} GREEN: {props.rag.green}</b> */}
                    {/* {props.data.map(({ key, display, value, data, details }) => { */}
                    {props.data.map(({ group_name, rag_overall, fields, header_value, header_units, display }) => {
                        // console.log(key, display, value, data)
                        // { group_name }

                        return (
                            <Grid item xs={12} md={6} lg={4}>
                                <AvrtElement group_name={group_name} rag_overall={rag_overall} fields={fields} header_value={header_value} header_units={header_units} />
                            </Grid>)

                        // return (

                        //     fields.map(({ data, key, value }) => {
                        //         console.log('>>FIELD>>', data, key, value)
                        //         return (
                        //             <Grid item xs={12} md={12} lg={12}>
                        //                 <Factor key={key} display={data.display} value={value} data={data} />
                        //             </Grid>
                        //         )

                        //     }))

                    })}
                </Grid>
                {/* <p>Key: {data.key}</p> */}
            </div>
        </div >

    );
}

export default AvrtGroup;