import React, { useState, createContext, useEffect } from "react";
import ReactDOM from 'react-dom';
import * as Survey from "survey-react"
import "survey-react/modern.css";
import "survey-react/survey.css";
import "../index.css";
import awsvars from "../aws.json"
import axios from "axios";
const SurveyContainer = (props) => {


    var survey = new Survey.Model();

    // ReactDOM.render(<Survey.Survey model={survey} />, document.getElementById("surveyElement"));;
    useEffect(() => {
        console.log('started')
        Survey
            .StylesManager
            .applyTheme("modern");
    }, []);


    var surveyJSON = { surveyId: '37d94cf0-334d-433a-b714-b0dd51344c9b', surveyPostId: '23c57c0a-bfab-4e5f-9cc8-f24042a4a1e9' }
    // var surveyJSON = { surveyId: '4898db2e-c738-42aa-bfba-5e8bc07dd1db', surveyPostId: 'a835f8bf-57a9-4ec5-9c4d-2aba22bcc4d3' }
    // survey
    //   .onComplete
    //   .add(function (sender) {
    //     document
    //       .querySelector('#surveyResult')
    //       .textContent = "Result JSON:\n" + JSON.stringify(sender.data, null, 3);
    //   });

    async function sendDataToServer(d) {
        const r = await axios.post(awsvars.api_gw_survey_url + '/patient_survey', d, { headers: { Authorization: 'Bearer ' + props.auth, 'Content-Type': 'application/json' } });
    }

    var survey = new Survey.Model(surveyJSON);
    survey.onComplete.add(function (survey_data) {
        console.log(survey_data)
        sendDataToServer(survey_data.data)
    })

    // survey.defaultBootstrapMaterialCss.navigationButton = "btn btn-green";
    // survey.defaultBootstrapMaterialCss.rating.item = "btn btn-default my-rating";
    // ReactDOM.render(<Survey.Survey model={survey} onValueChanged={sendDataToServer} />, document.getElementById("surveyElement"));
    // ReactDOM.render(<h1>footer</h1>, document.getElementById("footer"))
    return (
        <div>
            <Survey.Survey model={survey} />
        </div>

    );
}

export default SurveyContainer;