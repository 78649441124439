import React, { useState, createContext, useEffect } from "react";
import ReactDOM from 'react-dom';
import * as Survey from "survey-react"
import "survey-react/modern.css";
import "survey-react/survey.css";
import "../index.css";
import awsvars from "../aws.json"
import axios from "axios";
import Factor from "../ui_components/factor"
import { Grid, Card, CardHeader, CardContent } from '@material-ui/core';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red, amber, green, blue, grey } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Parser from 'html-react-parser';
const AvrtElement = (props) => {

    const [avrtData, setAvrtData] = useState({})
    const [ragColour, setRagColour] = useState(grey[500])
    const [subHeader, setSubHeader] = useState("")
    // var survey = new Survey.Model();

    // ReactDOM.render(<Survey.Survey model={survey} />, document.getElementById("surveyElement"));;
    useEffect(() => {
        // console.log('>>ELEMENT>>', props)
        if (props.rag_overall == 'red') {
            setRagColour("#b81820")
        }
        if (props.rag_overall == 'amber') {
            setRagColour("#f5b131")
        }
        if (props.rag_overall == 'green') {
            setRagColour("#1db58c")
        }
        if (props.header_value) {
            setSubHeader(props.header_value.toFixed(2))
            if (props.header_units) {
                setSubHeader(parseFloat(props.header_value.toFixed(2)) + ' ' + props.header_units)
            }
        }

    }, []);



    // survey.defaultBootstrapMaterialCss.navigationButton = "btn btn-green";
    // survey.defaultBootstrapMaterialCss.rating.item = "btn btn-default my-rating";
    // ReactDOM.render(<Survey.Survey model={survey} onValueChanged={sendDataToServer} />, document.getElementById("surveyElement"));
    // ReactDOM.render(<h1>footer</h1>, document.getElementById("footer"))
    return (
        <div>

            <div>
                <Card>

                    <CardHeader
                        avatar={
                            <Avatar sx={{ bgcolor: ragColour }} aria-label="recipe">
                                &nbsp;
                            </Avatar>
                        }
                        // action={
                        //     <IconButton aria-label="settings">
                        //         <MoreVertIcon />
                        //     </IconButton>
                        // }
                        titleTypographyProps={{ variant: 'headline' }}
                        title={props.group_name}
                        subheaderTypographyProps={{ variant: 'body2' }}
                        subheader={subHeader}
                    />
                    <CardContent>
                        <Typography variant="body2" color="text.secondary">



                            {props.fields.map(({ data, field_desc, value }) => {
                                // console.log('>>FIELD>>', data, field_desc, value)
                                return (
                                    <Grid item xs={12} md={12} lg={12}>
                                        <Factor field_desc={field_desc} display={data.display} value={value} data={data} />
                                    </Grid>
                                )
                            })}
                        </Typography>
                    </CardContent>
                </Card>

            </div>
        </div >

    );
}

export default AvrtElement;