import React, { useState, createContext, useEffect } from "react";
import ReactDOM from 'react-dom';
import * as Survey from "survey-react"
import "survey-react/modern.css";
import "survey-react/survey.css";
import "../index.css";
import awsvars from "../aws.json"
import axios from "axios";
import { Doughnut } from 'react-chartjs-2';
import { Grid } from '@material-ui/core';
import RagPie from "../ui_components/ragpie.js"

// we use this for charts https://www.npmjs.com/package/react-chartjs-2
const AvrtGroupSummary = (props) => {

    const [avrtData, setAvrtData] = useState({})
    const [slices, setSlices] = useState([0.1, 0.45, 0.2])
    // var survey = new Survey.Model();

    // ReactDOM.render(<Survey.Survey model={survey} />, document.getElementById("surveyElement"));;
    useEffect(() => {



    }, []);

    // survey.defaultBootstrapMaterialCss.navigationButton = "btn btn-green";
    // survey.defaultBootstrapMaterialCss.rating.item = "btn btn-default my-rating";
    // ReactDOM.render(<Survey.Survey model={survey} onValueChanged={sendDataToServer} />, document.getElementById("surveyElement"));
    // ReactDOM.render(<h1>footer</h1>, document.getElementById("footer"))
    return (



        <div>
            {/* <Grid container spacing={2}>
                <Grid item xs={8}>
                    aaaa
                </Grid>
                <Grid item xs={4}>
                    aaaa
                </Grid>
                <Grid item xs={4}>
                    aaaa
                </Grid>
                <Grid item xs={8}>
                    aaaa
                </Grid>
            </Grid> */}
            {/* <PieChart slices={slices} /> */}
            <a href={'#' + props.id}>
                <RagPie data={[props.rag.green, props.rag.amber, props.rag.red ]} title={props.name} />
            </a>
            {/* <a href={'#' + props.id}>
                <h2>{props.name}      {[...Array(props.rag.red)].map((e, i) => <span>&#128308; </span>)}
                    {[...Array(props.rag.amber)].map((e, i) => <span>&#128993; </span>)}
                    {[...Array(props.rag.green)].map((e, i) => <span>&#128994; </span>)}</h2>
            */}
            {/* <b>RED: {props.rag.red} AMBER: {props.rag.amber} GREEN: {props.rag.green}</b> */}

            {/* {props.data.map(({ key, display, value, data }) => {
                    console.log(key, display, value, data)
                    return (
                        <div>
                            <h4>{data.display}</h4>
                            <p>Value: {value}</p>
                            <p>Overall rag: {data.rag_overall}</p>

                        </div>
                    )

                })} */}
            {/* <p>Key: {data.key}</p> */}
        </div>


    );
}

export default AvrtGroupSummary;