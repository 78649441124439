import React, { useState, createContext, useEffect } from "react";
import ReactDOM from 'react-dom';
import * as Survey from "survey-react"
import "survey-react/modern.css";
import "survey-react/survey.css";
import "../index.css";
import awsvars from "../aws.json"
import axios from "axios";
import AvrtGroup from "../components/avrtgroup"
import AvrtGroupSummary from "../components/avrtgroupsummary"
import { Grid, Card, CardHeader, CardContent } from '@material-ui/core';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red, yellow, green, blue } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Factor from "../ui_components/factor"
import Trigger from "../ui_components/trigger"
import External from "../ui_components/external"
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import gup from '../libs/gup'
const AvrtData = (props) => {
    const [avrtDataFull, setAvrtDataFull] = useState([])
    const [avrtData, setAvrtData] = useState([])
    const [triggers, setTriggers] = useState([])
    const [patient, setPatient] = useState({})
    const [headerData, setHeaderData] = useState([])
    const [external, setExternal] = useState({ 'fields': [] })
    const [patientName, setPatientName] = useState({})
    const [avrt, setAvrt] = useState({})
    const [loaded, setLoaded] = useState(false)
    const [version, setVersion] = useState(1)
    // var survey = new Survey.Model();

    // ReactDOM.render(<Survey.Survey model={survey} />, document.getElementById("surveyElement"));;

    useEffect(() => {
        if (props.awsUrl === '') {
            return
        }
        async function fetch() {
            console.log('STAFF:AVRTpropsupdate:props', props)
            if (!props.patient_id) {
                console.log('no patient id, exiting')
                return
            }
            await axios.get(props.awsUrl + '/avrt_score_staff?patient_id=' + props.patient_id, { headers: { Authorization: 'Bearer ' + props.auth, 'Content-Type': 'application/json' } }).then(
                function (response) {
                    console.log('STAFF AVRT FROM SERVER propsupdsate ', response.data)
                    setAvrtDataFull(response.data)
                    setLoaded(true)
                }
            )
        }
        if (!props.auth) {
            console.log('exiting cos props.auth is ', props.auth)
            return
        }
        console.log('started')
        // Survey
        //     .StylesManager
        //     .applyTheme("modern");
        // hard code this to test
        fetch()
    }, []);
    useEffect(() => {
        console.log('UPDATING AVRTDATA', avrtDataFull)
        if (avrtDataFull.length === 0) {
            console.log('no data to update')
            return
        }
        console.log('pushing avrtdata to the right places')
        // V2_CHANGES
        // const v2_display = gup('v2', window.location.href)
        const v2_display = true
        console.log('V2_DISPLAY', v2_display)
        if (avrtDataFull && avrtDataFull.avrt_fe) {
            if (v2_display && avrtDataFull.avrt_fe.v2) {
                setVersion(2)
                setTriggers(avrtDataFull.avrt_fe.v2.triggers)
                // setPatient(avrtDataFull.avrt_fe.patient)
                setHeaderData(avrtDataFull.avrt_fe.v2.int_data)
                // setExternal({ 'description': 'ICHRON scores', 'fields': avrtDataFull.ichron_score })
                setAvrtData(avrtDataFull.avrt_fe.v2.data)
            } else {

                setPatient(avrtDataFull.avrt_fe.patient)
                setHeaderData(avrtDataFull.avrt_fe.int_data)
                // setExternal({ 'description': 'ICHRON scores', 'fields': avrtDataFull.ichron_score })
                setAvrtData(avrtDataFull.avrt_fe.data)
            }
        }


        console.log('set sample data')
        // console.log(avrtData)
    }, [avrtDataFull]);
    async function force_update() {

        setLoaded(false)
        if (props.awsUrl === '') {
            return
        }
        console.log('STAFF:AVRTpropsupdate:props', props)
        if (!props.patient_id) {
            console.log('no patient id, exiting')
            return
        }
        const v2_display = gup('v2', window.location.href)
        let version = ''
        if (v2_display) {
            version = '&v2=yes'
        }
        await axios.get(props.awsUrl + '/avrt_score_staff?refresh=' + Date.now() + '&patient_id=' + props.patient_id + version, { headers: { Authorization: 'Bearer ' + props.auth, 'Content-Type': 'application/json' } }).then(
            function (response) {
                console.log('STAFF AVRT FROM SERVER propsupdsate ', response.data)
                setAvrtDataFull(response.data)
                setLoaded(true)
            }
        )
    }
    useEffect(() => {
        async function fetch() {

            console.log('STAFF:AVRTpropsupdate:props', props)
            if (!props.patient_id) {
                console.log('no patient id, exiting')
                return
            }
            await axios.get(props.awsUrl + '/avrt_score_staff?patient_id=' + props.patient_id, { headers: { Authorization: 'Bearer ' + props.auth, 'Content-Type': 'application/json' } }).then(
                function (response) {
                    console.log('STAFF AVRT FROM SERVER propsupdsate ', response.data)
                    setAvrtDataFull(response.data)
                    setLoaded(true)
                }
            )
        }
        console.log('>>>AUTH UPDATE')
        fetch()
    }, [props.auth, props.patient_id, props.awsUrl]);

    // survey.defaultBootstrapMaterialCss.navigationButton = "btn btn-green";
    // survey.defaultBootstrapMaterialCss.rating.item = "btn btn-default my-rating";
    // ReactDOM.render(<Survey.Survey model={survey} onValueChanged={sendDataToServer} />, document.getElementById("surveyElement"));
    // ReactDOM.render(<h1>footer</h1>, document.getElementById("footer"))
    console.log('TRIGGERS, avrtdata.js', triggers)
    return (
        <div>
            <Grid item xs={12} md={12} lg={12}>
                {headerData.map(({ name, data, rag, id }) => {
                    // console.log('>>FULLDATA>>', data)

                    return (
                        <AvrtGroup name={name} data={data} rag={rag} id={id}></AvrtGroup>
                    )
                })}
            </Grid>
            <h2>AVRT data - summary</h2>

            <Button variant="contained" onClick={async () => { force_update() }}>Refresh AVRT calculation and Proforma</Button><br /><br />
            {loaded ?
                <>
                    {/* <a href={avrtDataFull.s3_timed_url}> <Button variant="contained">Download Proforma v1 docx</Button></a>  <br /><br /> */}

                    {version === 2 ?
                        <><a href={avrtDataFull.s3_timed_url_v2}> <Button variant="contained">Download Proforma v2 docx</Button></a>  <br /><br />
                            <a href={avrtDataFull.s3_timed_url_redacted}> <Button variant="contained">(Dev - redacted version) Download Proforma v2 docx</Button></a>  <br /><br />
                        </>
                        : <></>}
                    <Grid container spacing={2}>
                        {avrtData.map(({ name, data, rag, id }) => {
                            // console.log('>>DATA>>', data)

                            return (

                                <Grid item xs={12} md={4} lg={3}>
                                    <Card style={{ height: "300px" }}>


                                        <AvrtGroupSummary name={name} data={data} rag={rag} id={id}></AvrtGroupSummary>


                                    </Card>
                                </Grid>

                            )

                        })}
                    </Grid>
                    <h2>External Data Sources</h2>

                    <Grid container spacing={2}>
                        {external.fields.map(({ key, value, rag }) => {
                            // console.log('>>DATA>>', data)

                            return (
                                <Grid item xs={12} md={12} lg={12}>
                                    <h2>{external.description}</h2>
                                    <External vkey={key} value={value} rag={rag} />
                                    {/* {key} {value} {rag} */}
                                </Grid>

                                //
                                //     <Trigger key={key} display={display} value={value} rag={details.rag} details={details.msg} />
                                // </Grid>
                                // <Grid item xs={12} md={4} lg={3}>
                                //     <Card style={{ height: "300px" }}>
                                //         {display} - {details.msg}

                                //         {/* <AvrtGroupSummary name={name} data={data} rag={rag} id={id}></AvrtGroupSummary> */}


                                //     </Card>
                                // </Grid>

                            )

                        })}
                    </Grid >
                    <h2>Data Triggers</h2>

                    <Grid container spacing={2}>
                        {triggers.map(({ key, display, value, details }) => {
                            // console.log('>>DATA>>', data)
                            console.log("DETAILS, avrtdata.js", details)
                            return (
                                <Grid item xs={12} md={12} lg={12}>
                                    <Trigger key={key} display={display} value={value} details={details} />
                                </Grid>
                                // <Grid item xs={12} md={4} lg={3}>
                                //     <Card style={{ height: "300px" }}>
                                //         {display} - {details.msg}

                                //         {/* <AvrtGroupSummary name={name} data={data} rag={rag} id={id}></AvrtGroupSummary> */}


                                //     </Card>
                                // </Grid>

                            )

                        })}
                    </Grid>
                    <h2>AVRT data - full</h2>

                    {
                        avrtData.map(({ name, data, rag, id }) => {
                            console.log('>>FULLDATA>>', data)

                            return (
                                <AvrtGroup name={name} data={data} rag={rag} id={id}></AvrtGroup>
                            )
                        })
                    }
                </> : <>< CircularProgress /></>
            }
        </div >

    );
}

export default AvrtData;