import React, { useState, createContext, useEffect } from "react";
import ReactDOM from 'react-dom';
import * as Survey from "survey-react"
import "survey-react/modern.css";
import "survey-react/survey.css";
import "../index.css";
import awsvars from "../aws.json"
import axios from "axios";
const SurveyRegister = (props) => {


    var survey = new Survey.Model();

    // ReactDOM.render(<Survey.Survey model={survey} />, document.getElementById("surveyElement"));;
    const [submitResponse, setSubmitResponse] = useState('')
    useEffect(() => {
        console.log('started')
        Survey
            .StylesManager
            .applyTheme("modern");

    }, []);

    var surveyJSON = { surveyId: 'b128aa8f-ea00-45c6-92ce-026736f78c03', surveyPostId: '5765d6e6-a15e-41db-a86a-5fc01137a7af' }
    // survey
    //   .onComplete
    //   .add(function (sender) {
    //     document
    //       .querySelector('#surveyResult')
    //       .textContent = "Result JSON:\n" + JSON.stringify(sender.data, null, 3);
    //   });

    async function sendDataToServer(d) {
        const r = await axios.post(awsvars.api_gw_survey_url + '/register', d, { headers: { Authorization: 'Bearer ' + props.auth, 'Content-Type': 'application/json' } });
        console.log(r.data)
        setSubmitResponse(r.data)
    }

    var survey = new Survey.Model(surveyJSON);
    survey.onComplete.add(function (survey_data) {
        console.log(survey_data)
        sendDataToServer(survey_data.data)
    })

    // survey.defaultBootstrapMaterialCss.navigationButton = "btn btn-green";
    // survey.defaultBootstrapMaterialCss.rating.item = "btn btn-default my-rating";
    // ReactDOM.render(<Survey.Survey model={survey} onValueChanged={sendDataToServer} />, document.getElementById("surveyElement"));
    // ReactDOM.render(<h1>footer</h1>, document.getElementById("footer"))
    return (
        <div>
            <h1>Patient Registration</h1>
            {submitResponse != '' ? <h2>
                {submitResponse}
            </h2> :

                <Survey.Survey model={survey} />

            }
        </div>
    );
}

export default SurveyRegister;