import React, { useState, createContext, useEffect } from "react";
import ReactDOM from 'react-dom';
import * as Survey from "survey-react"
import "survey-react/modern.css";
import "survey-react/survey.css";
import "../index.css";
import awsvars from "../aws.json"
import axios from "axios";
import { Grid, Card, CardHeader, CardContent } from '@material-ui/core';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red, amber, green, blue } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
// import { CircularProgressWithLabel } from "@material-ui/core";
import CircularProgressWithLabel from '@mui/material/CircularProgress';
const Task = (props) => {

    // const [url, setUrl] = useState('')
    //assume one pareameter is already baked in
    const url = props.button_link + '&uuid=' + props.uuid

    var header = props.header
    // if (props.current_progress && props.total_actions) {
    //     var progress = (props.current_progress * 100 / props.total_actions).toFixed(0)
    //     var header = header + ' (' + progress + '% complete)'
    // }
    var complete = props.current_progress * 100 / props.total_actions
    if (props.status == 'done') {
        complete = 100
    }
    return (
        <Card>

            <CardHeader
                avatar={
                    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                        <CircularProgressWithLabel variant="determinate" value={complete} />
                        <Box
                            sx={{
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                                position: 'absolute',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Typography variant="caption" component="div" color="text.secondary">
                                {complete != 100 ? <> {`${Math.round(complete)}%`}</> :
                                    <>&#9989;</>}

                            </Typography>
                        </Box>
                    </Box>
                    // <>
                    //     <CircularProgressWithLabel variant="determinate" value={10} />
                    //     <Typography variant="caption" component="div" color="text.secondary">
                    //         {`10%`}
                    //     </Typography>
                    // </>
                }
                titleTypographyProps={{ variant: 'h5' }}
                title={header}
                subheaderTypographyProps={{ variant: 'h6' }}
                subheader={props.subheader}
            />
            <CardContent>

            </CardContent>
        </Card>

    )

}

export default Task;